import request, { baseUrl } from "@/utils/request";

/*
 *@描述: 获取所有国家
 *@参数:
 *@作者: 金涛
 *@日期: 2021-07-08 11:25:39
 */
export function getCountry() {
  return request({
    url: `${baseUrl.base}/common/getCountry`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述: 获取国家对应下的省
 *@参数: countryId: 国家id
 *@作者: 金涛
 *@日期: 2021-07-08 13:50:13
 */
export function getProvinces(countryId) {
  return request({
    url: `${baseUrl.base}/common/getStates/${countryId}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/*
 *@描述:获取所有市数据
 *@参数: provinceId: 省份ID
 *@作者: 金涛
 *@日期: 2021-07-08 14:16:43
 */
export function getCitys(countryId) {
  return request({
    url: `${baseUrl.base}/common/getCity/${countryId}`,
    method: "GET",
    headers: {
      needToken: true,
    },
  });
}

/**
 * 通用文件上传
 * @param {*} data
 * @returns
 */
export function uploadFile(data) {
  return request({
    url: `${baseUrl.base}/upload/uploadFile`,
    method: "POST",
    headers: {
      "Content-Type": "multipart/form-data",
      loading: false,
    },
    data,
    timeout: 30000,
  });
}
