<template>
  <van-form class="container">
    <div class="van-cell van-field tabs-content">
      <div class="van-cell__title van-field__label">
        <span>{{ $t("category") }}</span>
      </div>
      <div class="van-cell__value van-field__value">
        <div class="van-field__body">
          <ul class="switch-container" @click="hancleChangeHome">
            <li :class="switchChecked ? 'active' : ''">
              <van-image
                :src="
                  require(`@/assets/images/${
                    switchChecked ? 'jiajv' : 'jiajv_active'
                  }.png`)
                "
              />
              {{ $t("home") }}
            </li>
            <li :class="switchChecked ? '' : 'active'">
              <van-image
                :src="
                  require(`@/assets/images/${
                    switchChecked ? 'bangongshi' : 'bangongshi_active'
                  }.png`)
                "
              />
              {{ $t("office") }}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="van-cell van-field tabs-content">
      <div class="van-cell__title van-field__label">
        <span>{{ $t("address") }}</span>
      </div>
    </div>

    <!-- 国家地区选择 -->
    <text-input
      :readonly="true"
      v-model="nationalPMvalue"
      :placeholder="$t('nationalProvincesCities')"
      @click.native="showArea = true"
      right-icon="arrow-down"
    />
    <area-address
      :show-area="showArea"
      @close="showArea = false"
      @chose-success="choseSuccess"
    />
    <!-- 街道或大厦名 -->
    <text-input
      :placeholder="$t('streetBuildingName')"
      v-model="params.street"
    />
    <!-- 大厦/门牌号码/楼层/单位 -->
    <text-input :placeholder="$t('houseNumberFU')" v-model="params.detail" />

    <!-- 文件上传 -->
    <div class="van-cell van-field file-upload-title">
      <div class="van-cell__title van-field__label">
        <p>{{ $t("uploadFloorPlan") }}</p>
      </div>
    </div>
    <!-- 图片上传组件 -->
    <van-uploader
      v-model="fileList"
      multiple
      :max-count="9"
      :after-read="onFileRead"
    >
      <div class="style-upload">
        <van-icon name="plus" />
      </div>
    </van-uploader>

    <!-- 提交 -->
    <div class="submit-btn">
      <buttons @click.native="handleSubmit">
        {{ $t("submit") }}
      </buttons>
    </div>
  </van-form>
</template>
<script>
import TextInput from "@/components/TextInput";
import Buttons from "@/components/Buttons";
import AreaAddress from "@/components/AreaAddress";
import { uploadFile } from "@/api/base";
import { addHouse } from "@/api/addHomeOffice";

export default {
  name: "addHomeOffice",
  components: {
    TextInput,
    Buttons,
    AreaAddress,
  },
  data() {
    return {
      // 家居，办公室数据初始化
      switchChecked: true,

      // 国家地区选择参数初始化
      nationalPMvalue: "",
      showArea: false,

      // 上传文件初始化
      fileList: [],
      // 提交参数
      params: {
        type: 1,
        countryId: null,
        countryName: "",
        provinceId: null,
        provinceName: "",
        cityId: null,
        cityName: "",
        street: "",
        detail: "",
        flatImg: [],
      },
    };
  },
  methods: {
    // 切换家居以及办公室按钮
    hancleChangeHome() {
      this.switchChecked = !this.switchChecked;
      this.params.type = this.switchChecked ? 1 : 2;
    },

    // 监听国家省市选择成功结果
    choseSuccess(obj) {
      const { country, province, city } = obj;
      this.nationalPMvalue = country.name + province.name + city.name;
      this.params = {
        ...this.params,
        countryId: country.id,
        countryName: country.name,
        provinceId: province.id,
        provinceName: province.name,
        cityId: city.id,
        cityName: city.name,
      };
    },
    // 上传图片接收数据
    onFileRead(file) {
      let files = [];
      // 判断是否是数组
      let type = file instanceof Array;
      if (!type) {
        files = [file];
      } else {
        files = file;
      }
      files.map((item) => {
        item.message = this.$t("uploading");
        item.status = "uploading";
        item.url = "";
      });
      this.uploadFile(files, 0);
    },
    // 上传图片接口
    uploadFile(files, i) {
      var form = new FormData();
      form.append("file", files[i].file);
      uploadFile(form)
        .then((res) => {
          if (res.code == 200) {
            files[i].url = res.data.url;
            files[i].message = this.$t("uploadedSuccess");
            files[i].status = "done";
          } else {
            files[i].message = this.$t("uploadFailed");
            files[i].status = "failed";
          }
          // 成功后接着上传下一个
          i++;
          if (files.length === i) {
            // this.$toast({
            //   type: "success",
            //   message: this.$t("uploadCompleted"),
            //   forbidClick: true,
            // });
          } else {
            this.uploadFile(files, i);
          }
        })
        .catch(() => {
          files[i].message = this.$t("uploadFailed");
          files[i].status = "failed";
          i++;
          if (files.length === i) {
            // this.$toast({
            //   type: "success",
            //   message: this.$t("uploadFailed"),
            //   forbidClick: true,
            // });
          } else {
            this.uploadFile(files, i);
          }
        });
    },

    // 按钮提交
    handleSubmit() {
      const { countryId, provinceId, cityId, street, detail } = this.params;
      let { fileList } = this;
      // 判断国家省市区是否选择
      if (!countryId && !provinceId && !cityId) {
        this.$toast(this.$t("selectCountryProvinceCity"));
        return;
      }
      // 判断街道或大厦名是否填写
      if (!street) {
        this.$toast(this.$t("enterStreetName"));
        return;
      }
      // 判断大厦/门牌号码/楼层/单位是否填写
      if (!detail) {
        this.$toast(this.$t("enterBuildingHouseFloorUnit"));
        return;
      }
      // 判断是否上传平面图
      // if (fileList.length == 0) {
      //   this.$toast(this.$t("pleaseUploadFloorPlan"));
      //   return;
      // }
      // 把上传后的图片路径放到提交参数中
      if (fileList.length) {
        fileList.forEach((item) => {
          this.params.flatImg.push(item.url);
        });
      }
      // 新政接口
      addHouse(this.params).then((res) => {
        if (res.code == 200) {
          this.$router.replace({
            path: "/addHomeOffice/result",
          });
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 0.66rem 0.48rem 2.4rem;

  .switch-container {
    width: 2.4rem;
    height: 0.96rem;
    background: #eee7dc;
    border-radius: 0.48rem;
    display: flex;

    > li {
      flex: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      font-size: 0.24rem;
      font-weight: 400;
      color: #c4b494;
      transform: background 2s;
    }
    > li.active {
      background: #ab8438;
      border-radius: 0.48rem;
      color: #ffffff;
    }
  }

  .tabs-content:nth-child(2) {
    margin-bottom: 0;
  }

  .tabs-content {
    margin-bottom: 0.3rem;
    .van-field__value {
      display: flex;
      justify-content: flex-end;
    }
  }

  .file-upload-title {
    margin-bottom: 0.3rem;
    .van-cell__title {
      width: 100%;
      display: flex;
      align-items: center;

      > p:last-child {
        color: #afafaf;
        font-weight: 400;
      }
    }
  }
  .file-upload-content {
    margin-top: 0.3rem;
    height: auto;
  }
  .style-upload {
    width: 1.96rem;
    height: 1.96rem;
    border: 0.04rem dashed rgba(171, 132, 56, 0.36);
    border-radius: 0.24rem;
    display: flex;
    justify-content: center;
    align-items: center;
    .van-icon {
      font-size: 0.64rem;
      color: rgba(171, 132, 56, 0.36);
    }
  }

  .submit-btn {
    height: 1.8rem;
    padding: 0 0.48rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
/deep/ {
  .van-cell::after {
    display: none;
  }

  .van-cell {
    height: 0.96rem;
    padding: 0;
    align-items: center;

    .van-cell__title {
      font-size: 0.32rem;
      font-weight: 700;
      color: #515151;
    }

    .van-cell__title > span::after {
      content: "*";
      color: #ab8438;
      vertical-align: middle;
    }

    .van-image {
      width: 0.32rem;
      height: 0.32rem;
      margin-top: 0.14rem;
    }
  }
  .van-uploader__preview-delete {
    background: #da2929;
    border-radius: 50%;
    top: -4px;
    right: -4px;
  }
  .van-uploader__preview-image {
    width: 1.96rem;
    height: 1.96rem;
    border-radius: 0.24rem;
  }
  .van-uploader__preview-delete-icon {
    top: -1px;
    right: -1px;
  }
  .van-uploader__mask {
    border-radius: 0.24rem;
  }
}
</style>
