import request, { baseUrl } from "@/utils/request";

/**
 * 新增家宅平面图
 * @param {*} data
 * @returns
 */
export function addHouse(data) {
  return request({
    url: `${baseUrl.api}/house/addHouse`,
    method: "POST",
    headers: {
      needToken: true,
    },
    data,
  });
}
