<template>
  <van-action-sheet
    v-model="show"
    :title="$t('selectRegion')"
    @close="handleClose"
  >
    <div class="area-address-info">
      <!-- 顶部选择结果展示 -->
      <ul class="chose-result">
        <li
          class="chose-result-item"
          @click="choseCountry"
          :class="choseActiveCountry ? 'active' : ''"
        >
          {{
            selectedCountryObj.name
              ? selectedCountryObj.name
              : $t("pleaseChoose")
          }}
        </li>
        <li
          class="chose-result-item"
          @click="choseProvince"
          :class="choseActiveProvince ? 'active' : ''"
          v-show="showProvinceName"
        >
          {{
            selectedProvinceObj.name
              ? selectedProvinceObj.name
              : $t("pleaseChoose")
          }}
        </li>
        <li
          class="chose-result-item"
          @click="choseCity"
          :class="choseActiveCity ? 'active' : ''"
          v-show="showCityName"
        >
          {{ selectedCityObj.name ? selectedCityObj.name : $t("pleaseChoose") }}
        </li>
      </ul>

      <!-- 选择国家省市class="active" -->
      <div class="chose-opeartion">
        <ul>
          <!-- 所有国家 -->
          <li
            v-for="(item, index) in countrys"
            :key="index"
            @click="handleCountry(item, index)"
            :class="item.selected ? 'active' : ''"
            v-show="showCountrys"
          >
            {{ item.name }}
          </li>

          <!-- 所有省 -->
          <li
            v-for="(itm, idx) in provinces"
            :key="`${idx}-${idx}`"
            @click="handleProvince(itm, idx)"
            :class="itm.selected ? 'active' : ''"
            v-show="showProvince"
          >
            {{ itm.name }}
          </li>

          <!-- 所有市 -->
          <li
            v-for="(i, inde) in citys"
            :key="`${inde}-${inde}-${inde}`"
            @click="handleCity(i, inde)"
            :class="i.selected ? 'active' : ''"
            v-show="showCity"
          >
            {{ i.name }}
          </li>
        </ul>
      </div>
    </div>
  </van-action-sheet>
</template>
<script>
import { getCountry, getProvinces, getCitys } from "@/api/base";
export default {
  name: "AreaAddress",
  props: {
    showArea: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      // 弹窗状态
      show: false,
      // 顶部选中结果的状态
      choseActiveCountry: true,
      choseActiveProvince: false,
      choseActiveCity: false,
      // 国家数据集合
      countrys: [],
      // 省数据集合
      provinces: [],
      // 市数据集合
      citys: [],
      // 国家数据列表初始化显示
      showCountrys: true,
      // 省份数据列表初始化显示
      showProvince: false,
      // 市数据列表初始化显示
      showCity: false,
      // 选中的省名称初始化隐藏
      showProvinceName: false,
      // 选中的市名称初始化隐藏
      showCityName: false,
      // 用户选择国家 省 市的时候存储一下用户的选择，以便于用户在更改选择的时候进行判断
      beforeChoseCountryName: "",
      beforeChoseProvinceName: "",
      beforeChoseCityName: "",
      // 选中的国家对象
      selectedCountryObj: {},
      // 选中的省的对象
      selectedProvinceObj: {},
      // 选中的市的对象
      selectedCityObj: {},
    };
  },
  watch: {
    showArea(newVal) {
      this.show = newVal;
    },
  },
  created() {
    this.getCountry();
  },
  methods: {
    // 弹窗层收起状态监听
    handleClose() {
      let isSelectedCountryObj =
          JSON.stringify(this.selectedCountryObj) === "{}" ? true : false,
        isSelectedProvinceObj =
          JSON.stringify(this.selectedProvinceObj) === "{}" ? true : false,
        isSelectedCityObj =
          JSON.stringify(this.selectedCityObj) === "{}" ? true : false;
      this.$emit(
        "close",
        isSelectedCountryObj ? false : this.selectedCountryObj,
        isSelectedProvinceObj ? false : this.selectedProvinceObj,
        isSelectedCityObj ? false : this.selectedCityObj
      );
    },

    // 获取所有国家
    getCountry() {
      this.countrys = [];
      getCountry().then((res) => {
        res.data.map((item) => {
          item.selected = false;
        });
        this.countrys = res.data;
      });
    },

    // 获取当前国家下的所有省
    getProvinces(countryId) {
      this.provinces = [];
      getProvinces(countryId).then((res) => {
        res.data.map((item) => {
          item.selected = false;
        });
        this.provinces = res.data;
      });
    },

    // 获取当前省下的所有市
    getCitys(provinceId) {
      this.citys = [];
      getCitys(provinceId).then((res) => {
        res.data.map((item) => {
          item.selected = false;
        });
        this.citys = res.data;
      });
    },

    // 选择国家
    handleCountry(items, index) {
      let beforeChoseCountryName = this.beforeChoseCountryName;
      // 如果选中的是之前选中的国家那么正常切换到省进行展示，不再次调用获取省的接口
      if (items.name !== beforeChoseCountryName) {
        // 点击选择当前高亮
        this.countrys.map((item) => (item.selected = false));
        this.countrys[index].selected = true;

        // 获取当前国家下的省数据
        this.getProvinces(items.id);

        this.selectedProvinceObj = {};
        this.beforeChoseProvinceName = "";
        this.selectedCityObj = {};
        this.beforeChoseCityName = "";
        this.showProvinceName = true;
        this.showCityName = false;
        this.setCountry(items);
      }

      this.choseActiveCountry = false;
      this.choseActiveProvince = true;
      this.choseActiveCity = false;
      this.showCountrys = false;
      this.showProvince = true;
      this.showCity = false;
    },

    // 设置选中国家值
    setCountry(item) {
      this.selectedCountryObj = item;
      this.beforeChoseCountryName = item.name;
    },

    // 选择省份
    handleProvince(items, index) {
      let beforeChoseProvinceName = this.beforeChoseProvinceName;

      // 如果选中的是之前选中的省那么正常切换到市进行展示，不再次调用获取市的接口
      if (items.name !== beforeChoseProvinceName) {
        // 点击选择当前高亮
        this.provinces.map((item) => (item.selected = false));
        this.provinces[index].selected = true;

        // 获取当前省下的市数据
        this.getCitys(items.id);

        this.selectedCityObj = {};
        this.beforeChoseCityName = "";
        this.showProvinceName = true;
        this.showCityName = true;
        this.setProvince(items);
      }
      this.choseActiveCountry = false;
      this.choseActiveProvince = false;
      this.choseActiveCity = true;
      this.showCountrys = false;
      this.showProvince = false;
      this.showCity = true;
    },

    // 设置选中省份值
    setProvince(item) {
      this.selectedProvinceObj = item;
      this.beforeChoseProvinceName = item.name;
    },

    // 选择市
    handleCity(items, index) {
      let beforeChoseCityName = this.beforeChoseCityName;
      if (items.name !== beforeChoseCityName) {
        this.citys.map((item) => (item.selected = false));
        this.citys[index].selected = true;

        this.setCity(items);
      }

      let choseSuccessFullObj = {
        country: this.selectedCountryObj,
        province: this.selectedProvinceObj,
        city: this.selectedCityObj,
      };

      this.show = false;
      this.$emit("chose-success", choseSuccessFullObj);
    },

    // 选择市的值
    setCity(item) {
      this.selectedCityObj = item;
      this.beforeChoseCityName = item.name;
    },

    // 顶部选中国家监听点击
    choseCountry() {
      // 重置国家 省 市数据展示状态
      this.showCountrys = true;
      this.showCity = false;
      this.showProvince = false;

      // 重置顶部高亮状态
      this.choseActiveCountry = true;
      this.choseActiveProvince = false;
      this.choseActiveCity = false;
    },

    // 顶部选中省监听点击
    choseProvince() {
      // 重置国家 省 市数据展示状态
      this.showCountrys = false;
      this.showCity = false;
      this.showProvince = true;

      // 重置顶部高亮状态
      this.choseActiveCountry = false;
      this.choseActiveProvince = true;
      this.choseActiveCity = false;
    },

    // 顶部选中市监听点击
    choseCity() {
      // 重置国家 省 市数据展示状态
      this.showCountrys = false;
      this.showCity = true;
      this.showProvince = false;

      // 重置顶部高亮状态
      this.choseActiveCountry = false;
      this.choseActiveProvince = false;
      this.choseActiveCity = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.van-action-sheet {
  padding: 0.48rem 0 0 0.38rem;
  height: 80%;

  .van-action-sheet__header {
    font-size: 0.32rem;
    font-weight: 700;
    text-align: left;
    color: #515151;
    line-height: normal;

    .van-action-sheet__close {
      top: 0.5rem;
      color: #cdcdcd;
      font-size: 0.36rem;
    }
  }

  .van-action-sheet__content {
    padding-top: 0.54rem;
    font-size: 0.28rem;
    color: #515151;

    .chose-opeartion {
      position: absolute;
      top: 2.88rem;
      left: 0.38rem;
      right: 0;
      bottom: 0.48rem;
      overflow-y: auto;

      ul {
        display: flex;
        flex-direction: column;
      }

      li {
        display: inline-block;
        margin-bottom: 0.48rem;
      }

      li.active {
        color: #ab8438;
      }
    }
  }
}

.chose-result {
  display: flex;

  .chose-result-item {
    margin-right: 0.6rem;
  }

  .active {
    position: relative;
  }

  .active::after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: -0.06rem;
    width: 0.48rem;
    height: 0.06rem;
    background: #ab8438;
    margin: 0 auto;
    border-radius: 0.04rem;
  }
}
.van-overlay {
  background-color: initial;
}
</style>
