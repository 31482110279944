<template>
  <van-field
    v-bind="$attrs"
    v-on="$listeners"
    class="input-cocntainer"
    :readonly="readonly"
    @input="handleValue"
    v-model="val"
  />
</template>

<script>
export default {
  name: "TextInput",
  data() {
    return {
      val: "",
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    value(newVal) {
      this.val = newVal;
    },
  },
  methods: {
    handleValue(value) {
      this.$emit("input", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.input-cocntainer {
  padding-right: 0.42rem;
  margin-bottom: 0.3rem;
  background: #ffffff;
  border-radius: 0.48rem;
  box-shadow: 0.04rem 0.04rem 0.14rem 0 rgba(88, 72, 57, 0.3) inset;

  /deep/ {
    .van-cell__value {
      margin-left: 0.48rem;
      margin-right: 0.48rem;
    }

    .van-field__body {
      width: 100%;
      .van-field__control {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        font-size: 0.28rem;
      }
    }
  }
}
</style>
