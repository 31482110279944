<template>
  <van-button
    round
    hairline
    block
    type="info"
    color="#d1b06f"
    class="button-style"
  >
    <slot />
  </van-button>
</template>

<script>
export default {
  name: "Buttons",
};
</script>

<style lang="scss" scoped>
.button-style {
  box-shadow: 0 0.1rem 0.2rem 0 rgba(96, 70, 20, 0.5);
}
</style>
